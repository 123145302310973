var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout-banner",
    [
      _c(
        "div",
        { staticClass: "vh100 content-center" },
        [
          _c(
            "b-card",
            {
              staticClass: "mb-5 text-center custom-card",
              staticStyle: { width: "500px" },
            },
            [
              _c(
                "div",
                { staticClass: "content-center mb-3" },
                [
                  _c("router-link", { attrs: { to: "/login" } }, [
                    _c("i", { staticClass: "las la-arrow-left user-icon" }),
                  ]),
                  _c(
                    "label",
                    { staticClass: "h4 w-100 fs-24 primary fw-bold mb-0" },
                    [_vm._v(" Recuperar Contraseña ")]
                  ),
                ],
                1
              ),
              _c(
                "b-card-text",
                { staticClass: "text-justify fs-14 primary" },
                [
                  _c(
                    "b-form",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "text-left",
                          attrs: { label: "Número de identificación" },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              placeholder: "Numero de Identificación",
                              state: _vm.$v.cedula.$dirty
                                ? !_vm.$v.cedula.$error
                                : null,
                            },
                            model: {
                              value: _vm.$v.cedula.$model,
                              callback: function ($$v) {
                                _vm.$set(_vm.$v.cedula, "$model", $$v)
                              },
                              expression: "$v.cedula.$model",
                            },
                          }),
                          _c("b-form-invalid-feedback", [
                            _vm._v(_vm._s(_vm.validarCampo(_vm.$v.cedula))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "m-3",
                          attrs: { variant: "danger" },
                          on: { click: _vm.generarCodigo },
                        },
                        [
                          _c("i", { staticClass: "las la-arrow-circle-right" }),
                          _vm._v(" Solicitar "),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ModalCambiarClave", {
        attrs: { isOnLogin: false, modal: _vm.modal.clave },
        on: { useModal: _vm.useModal, sendForm: _vm.terminarProceso },
      }),
      _c("ModalValidarOtp", {
        ref: "modalOtp",
        attrs: {
          tiempoReenvioCodigo: _vm.tiempoHabilitarReenvio,
          digitosCelular: _vm.digitosCelular,
          modal: _vm.modalOtp,
        },
        on: {
          reenviarCodigo: _vm.reenviarCodigo,
          sendForm: _vm.validarCodigo,
          useModal: _vm.useModalOtp,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }