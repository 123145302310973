var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { centered: "", "hide-footer": "" },
      on: {
        close: function ($event) {
          return _vm.onCloseModal()
        },
      },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [_vm._v(" Cambiar Contraseña ")]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.modal.isOpen,
        callback: function ($$v) {
          _vm.$set(_vm.modal, "isOpen", $$v)
        },
        expression: "modal.isOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "py-2" },
        [
          _c("FormCambiarClave", {
            attrs: { isOnLogin: _vm.isOnLogin, recuperarClave: true },
            on: { terminarProceso: _vm.terminarProceso },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }