<template>
  <layout-banner>
    <div class="vh100 content-center">
      <b-card style="width: 500px" class="mb-5 text-center custom-card">
        <div class="content-center mb-3">
          <router-link to="/login"><i class="las la-arrow-left user-icon" /></router-link>
          <label class="h4 w-100 fs-24 primary fw-bold mb-0"> Recuperar Contraseña </label>
        </div>
        <b-card-text class="text-justify fs-14 primary">
          <b-form class="text-center">
            <b-form-group label="Número de identificación" class="text-left">
              <b-form-input
                v-model="$v.cedula.$model"
                placeholder="Numero de Identificación"
                :state="$v.cedula.$dirty ? !$v.cedula.$error : null"
              />
              <b-form-invalid-feedback>{{ validarCampo($v.cedula) }}</b-form-invalid-feedback>
            </b-form-group>
            <b-button variant="danger" class="m-3" @click="generarCodigo">
              <i class="las la-arrow-circle-right" /> Solicitar
            </b-button>
          </b-form>
        </b-card-text>
      </b-card>
    </div>
    <ModalCambiarClave :isOnLogin="false" :modal="modal.clave" @useModal="useModal" @sendForm="terminarProceso" />
    <ModalValidarOtp
      :tiempoReenvioCodigo="tiempoHabilitarReenvio"
      :digitosCelular="digitosCelular"
      @reenviarCodigo="reenviarCodigo"
      @sendForm="validarCodigo"
      @useModal="useModalOtp"
      :modal="modalOtp"
      ref="modalOtp"
    />
  </layout-banner>
</template>

<script>
import ComunesService from "@/app/core/api/comunes";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";
import { required, numeric, maxLength } from "vuelidate/lib/validators";

// COMPONENTS
import LayoutBanner from "@/app/shared/layouts/Banner.vue";
import ModalCambiarClave from "../components/ModalCambiarClave.vue";
import ModalValidarOtp from "@/app/shared/components/ModalValidarOtp.vue";

export default {
  mixins: [mixinsEvents],
  components: { LayoutBanner, ModalCambiarClave, ModalValidarOtp },
  data() {
    return {
      cedula: null,
      digitosCelular: null,
      modalOtp: { isOpen: false },
      tiempoHabilitarReenvio: null,
      modal: { clave: { isOpen: false } },
    };
  },
  validations: {
    cedula: { required, numeric, maxLength: maxLength(12) },
  },
  methods: {
    useModal(modal) {
      this.modal[modal].isOpen = !this.modal[modal].isOpen;
    },
    useModalOtp() {
      this.modalOtp.isOpen = !this.modalOtp.isOpen;
    },
    generarCodigo() {
      const body = { cedula: this.cedula, idSolicitud: 0, estado: 0, tipo: "2" };

      ComunesService.generarCodigoRecuperarClave(body).then((response) => {
        if (response.data.estado) {
          this.tiempoHabilitarReenvio = response.data.tiempoHabilitarReenvio;
          this.digitosCelular = response.data.celular.slice(-4);
          setTimeout(() => this.$refs.modalOtp.iniciarContador(), 0);
          this.modalOtp.isOpen = true;
          this.$store.commit("setUserData", { identificacion: this.cedula });
          AlertsService.success("Recuperación de contraseña", response.data.mensaje);
        }
      });
    },
    validarCodigo(codigo) {
      const body = { cedula: this.cedula, codigoOTP: codigo };

      ComunesService.validarCodigoRecuperarClave(body).then((response) => {
        AlertsService[response.data.estado ? "success" : "error"]("Validación Código OTP", response.data.mensaje);
        if (response.data.estado) {
          this.useModalOtp();
          this.useModal("clave");
        }
        if (response.data.cerrarModal) this.modalOtp.isOpen = false;
      });
    },
    reenviarCodigo() {
      const body = { cedula: this.cedula, idSolicitud: 0, estado: 1, tipo: "1" };

      ComunesService.reenviarCodigoRecuperarClave(body).then((response) => {
        if (response.data.estado) {
          this.tiempoHabilitarReenvio = response.data.tiempoHabilitarReenvio;
          setTimeout(() => this.$refs.modalOtp.iniciarContador(), 0);
          AlertsService.success("Recuperación de contraseña", response.data.mensaje);
        }
      });
    },
    terminarProceso() {
      this.cedula = null;
      this.$store.commit("cleanUserData");
      this.$nextTick(() => this.$v.$reset());
      this.$router.push("/login");
    },
  },
};
</script>
