<template>
  <b-modal centered hide-footer v-model="modal.isOpen" @close="onCloseModal()">
    <template #modal-title>
      Cambiar Contraseña
    </template>
    <div class="py-2">
      <FormCambiarClave :isOnLogin="isOnLogin" :recuperarClave="true" @terminarProceso="terminarProceso" />
    </div>
  </b-modal>
</template>

<script>
import FormCambiarClave from "@/app/shared/components/FormCambiarClave.vue";

export default {
  components: { FormCambiarClave },
  props: {
    isOnLogin: { type: Boolean, default: true },
    modal: {
      type: Object,
      default: () => {
        return { isOpen: false };
      },
    },
  },
  methods: {
    onCloseModal() {
      this.$emit("useModal", "clave");
    },
    terminarProceso() {
      this.$emit("sendForm");
      this.onCloseModal();
    },
  },
};
</script>
